import React from 'react';

import { Box } from '@core';
import { LOADING_OPTIONS } from '@components';
import { FadeInTitle, TitleWithIncrementalDiscount } from '@shared/deals-helpers';
import { getFetchedImage } from '@shared/image-helpers';

import { PAGE_DATA as DEALS_PAGE_DATA } from './deals';

export const PAGE_DATA = (props) => ({
  ...DEALS_PAGE_DATA(props),
  headerCta: {
    text: ({ t, discountPercentage, isLoading, ...headerProps }) => ({
      ...DEALS_PAGE_DATA(props).headerCta.text({ t, ...headerProps }),
      title: discountPercentage ? (
        <TitleWithIncrementalDiscount
          finalDiscountPercentage={!isLoading && discountPercentage}
          i18nKey="campaigns:formulaCampaign.title"
          components={[
            <FadeInTitle color="danger" as="span" fontFamily="inherit" whiteSpace="nowrap" key={0}>
              <Box display="inline-flex" justifyContent="center" minWidth={{ _: 75, lg: 112 }} />
            </FadeInTitle>,
          ]}
        />
      ) : (
        t('deals:header.getDeal')
      ),
    }),
    image: () => ({
      mobile: {
        src: getFetchedImage(props.images, 'deals/deals-header/deals-f1-header.png'),
        alt: 'Deals F1 header',
        position: {
          height: 341,
          mt: -28,
        },
        loading: LOADING_OPTIONS.eager,
      },
      tablet: {
        src: getFetchedImage(props.images, 'deals/deals-header/deals-f1-header.png'),
        alt: 'Deals F1 header',
        position: {
          height: 523,
        },
        loading: LOADING_OPTIONS.eager,
      },
      desktop: {
        src: getFetchedImage(props.images, 'deals/deals-header/deals-f1-header.png'),
        alt: 'Deals F1 header',
        position: {
          height: { lg: 450, xxl: 516 },
          left: { lg: '55%', xxl: '52%' },
          top: 10,
          right: { lg: -140, xl: 'unset' },
        },
        loading: LOADING_OPTIONS.eager,
      },
      isImageAnimated: false,
    }),
  },
});
