import React from 'react';
import { Helmet } from 'react-helmet';
import { graphql } from 'gatsby';
import { ThemeProvider } from 'styled-components';

import { DealsPageTemplate } from '@templates/deals/deals';
import { PAGE_DATA } from '@shared/constants/pages/deals-f1';
import { dealsTheme } from '@theme';

const DealsPage = (props) => (
  <ThemeProvider theme={dealsTheme}>
    <Helmet>
      <meta name="robots" content="noindex" />
    </Helmet>
    <DealsPageTemplate
      {...props}
      {...PAGE_DATA({ variant: 'dark', images: props.data.allFile.edges })}
    />
  </ThemeProvider>
);

export default DealsPage;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(
      filter: {
        language: { eq: $language }
        ns: { in: ["common", "navigation", "deals", "plans", "featureTabs", "campaigns"] }
      }
    ) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    allFile(
      filter: {
        relativePath: {
          in: [
            "deals/deals-header/deals-f1-header.png"
            "deals/money-back-guarantee.png"
            "deals/review-logos/vpn-mentor.png"
            "deals/review-logos/top10-vpn.png"
            "deals/review-logos/cybernews.png"
            "deals/review-logos/the-best-vpn-deals.png"
            "deals/review-logos/vpn-pro.png"
            "deals/review-logos/vpn-alert.png"
            "features/streaming-dark.png"
            "features/privacy-dark.png"
            "features/speed-dark.png"
            "features/security-dark.png"
          ]
        }
        sourceInstanceName: { eq: "images" }
      }
    ) {
      edges {
        ...imageData
      }
    }
  }
`;
